import React from 'react'
import './Loader.scss'

const Loader = props => (
	<div className={`spinner-wrapper spinner-wrapper--${props.class}`}>
		<div></div>
		<div></div>
		<div></div>
		<div></div>
	</div>
)

export default Loader
