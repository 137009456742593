import React from 'react'
import PropTypes from 'prop-types'
import Loader from '../../atoms/Loader/Loader'
import './buttonLoading.scss'

const ButtonLoading = props => {
	const { button, isShowingLoader, bgClass, isEnabled } = props
	return (
		<div
			className={`f-button-loader f-button-loader--${bgClass}`}
			data-enabled={!isEnabled}
		>
			{button}
			{isShowingLoader && <Loader class={bgClass} />}
		</div>
	)
}

ButtonLoading.propTypes = {
	button: PropTypes.node.isRequired,
	isShowingLoader: PropTypes.bool.isRequired,
	bgClass: PropTypes.string,
	isEnabled: PropTypes.bool,
}

export default ButtonLoading
